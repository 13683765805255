<template>
  <div class="video-player" :class="{loading}">
    <div v-if="displayTitle" class="video-title">{{ displayTitle }} {{ subTitle }}</div>
    <ul class="video-toolbox">
<!--      <li title="查询录像"><i class="video-iconfont icon-record" @click.stop="doRecord" /></li>-->
      <li title="全屏"><i class="video-iconfont icon-full" @click.stop="doFull" /></li>
      <li title="关闭"><i class="video-iconfont icon-close" @click.stop="$emit('close')" /></li>
    </ul>
    <video v-if="webrtc || rtsp" ref="video" autoplay playinside :muted="muted" @playing="loading = false" />
    <div v-if="showRecord" class="video-record-container" @click.stop="">
      <Datepicker v-model="startDate" format="yyyy-MM-dd" :language="locale" calendar-class="calendar" />
      <span>至</span>
      <Datepicker v-model="endDate" format="yyyy-MM-dd" :language="locale" calendar-class="calendar" />
      <button @click.stop="doSearch"><i class="video-iconfont icon-search" />查询</button>
      <ul v-if="records && records.length > 0" class="record-list">
        <li v-for="(item,index) in records" :key="index" @click.stop="playback(item.uBeginTime,item.uEndTime,item.nSource)">
          {{ dateFormat('yyyy-MM-dd HH:mm', new Date(item.uBeginTime * 1000)) }}  至  {{ dateFormat('yyyy-MM-dd HH:mm', new Date(item.uEndTime * 1000)) }}
        </li>
      </ul>
    </div>
    <div v-if="showRecordControl" class="video-record-controls">
      <input
        v-model="recordValue"
        type="range"
        :min="recordMinValue"
        :max="recordMaxValue"
        step="1"
        @change="playRecord(recordValue,recordMaxValue,recordSource)"
        @mousedown="recordDraging = true"
        @mouseup="recordDraging = false"
      ><span>{{ dateFormat('yyyy-MM-dd HH:mm:ss', new Date(recordValue * 1000)) }}</span>
    </div>
    <canvas v-if="!webrtc && !rtsp" v-show="!loading" ref="video" />
    <div v-for="(item, key) in bbox" :key="key" :style="item.style"></div>
  </div>
</template>
<script>
import WebRtcStreamer from '@/assets/video/webrtc/webrtc.js'
import JSMpeg from '@/assets/video/jsmpeg/jsmpeg.js'
import Flvjs from 'flv.js'
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { zh } from 'vuejs-datepicker/dist/locale'
import { Base64 } from '@/utils/base64.js'
export default {
  name: 'VideoPlayer',
  components: { Datepicker },
  props: {
    server: {
      type: String,
      default: process.env.VUE_APP_VIDEO_SERVER
    },
    videoUrl: {
      type: String,
      default: ''
    },
    abilities: {
      type: Array
    },
    videoId: {
      type: String,
      default: ''
    },
    displayTitle: {
      type: String,
      default: '摄像头'
    },
    audioUrl: {
      type: String,
      default: ''
    },
    muted: {
      type: Boolean,
      default: false
    },
    options: {
      type: String,
      default: 'rtptransport=tcp&timeout=60'
    }
  },
  data() {
    const now = new Date()
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
    return {
      streamer: null,
      loading: true,
      showRecord: false,
      webrtc: this.server.startsWith('http') && !this.videoUrl && !this.videoUrl.startsWith('rtsp'),
      rtsp: this.videoUrl && this.videoUrl.startsWith('rtsp'),
      startDate: new Date(start),
      endDate: new Date(start),
      locale: zh,
      records: [],
      bbox: [],
      subTitle: null,
      showRecordControl: false,
      recordValue: 0,
      recordMinValue: 0,
      recordMaxValue: 3600,
      recordOffset: 0,
      recordSource: null,
      recordDraging: false,
      websocket: null,
      timer: null,
    }
  },
  watch: {
    videoUrl() {
      this.loading = true
      this.connect()
    }
  },
  mounted() {
    this.connect()
    document.addEventListener('visibilitychange', this.visibilitychange)
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilitychange)
    this.disconnect()
  },
  methods: {
    doFull() {
      this.$emit('full')
    },
    reloadVideo() {
      this.disconnect()
      this.connect()
    },
    doSearch() {
      const start = this.startDate.getTime() / 1000
      const end = this.endDate.getTime() / 1000 + 86400
      fetch(process.env.VUE_APP_VIDEO_SERVER + `video/records?deviceId=${this.videoId}&startTime=${start}&endTime=${end}`).then(response => response.json()).then(json => {
        if (json.code === 1) {
          this.records = json.data
          if (!json.data || json.data.length === 0) {
            alert('该时间段内无录像')
          }
        } else if (json.code !== undefined) {
          alert(json.message)
        } else {
          alert('查询失败')
        }
      }).catch(() => {
        alert('查询失败')
      })
    },
    visibilitychange() {
      if (!document.hidden && this.rtsp && this.$refs.video) {
        if (this.$refs.video.buffered.length) {
          const buffered = this.$refs.video.buffered
          if (buffered.length > 0) {
            const end = buffered.end(0)
            if (end - this.$refs.video.currentTime > 0.15) {
              this.$refs.video.currentTime = end - 0.1
            }
          }
        }
      }
    },
    doRecord() {
      this.showRecord = !this.showRecord
    },
    connect() {
      if (this.webrtc) {
        this.streamer = new WebRtcStreamer(this.$refs.video, this.server + 'stream')
        this.streamer.connect(this.videoId, this.videoUrl, this.audioUrl, this.options)
      } else if (this.rtsp) {
        if(this.abilities && this.abilities.indexOf('ship') > -1){
          let wsUrl = process.env.VUE_APP_VIDEO_FLV_SERVER + '/event/ship/' + Base64.encodeURL(this.videoUrl);
          this.websocket = new WebSocket(wsUrl.replace('/live/', '/').replace(/^http/, 'ws'))
          this.websocket.onmessage = (e)=> {
            this.bbox = JSON.parse(e.data).filter(it => Number(it[3]) > 0.3).map(it => {
              const rect = it[0];
              return {
                style: {
                  position: 'absolute',
                  width: rect[2] * 100 + '%',
                  height: rect[3] * 100 + '%',
                  left: (rect[0] - rect[2] / 2) * 100 + '%',
                  top: (rect[1] - rect[3] / 2) * 100 + '%',
                  border: '2px solid #00ff00'
                }
              }
            })
            this.timer !== null && clearTimeout(this.timer)
            this.timer = setTimeout(() => this.bbox = [], 2000)
          }
        }
        this.streamer = Flvjs.createPlayer({
          type: 'flv',
          url: process.env.VUE_APP_VIDEO_FLV_SERVER + '/flv/' + Base64.encodeURL(this.videoUrl),
          isLive: true
        })
        this.streamer.attachMediaElement(this.$refs.video)
        this.streamer.load()
        this.streamer.play()
        this.streamer.on(Flvjs.Events.ERROR, () => {
          if (this.streamer) {
            this.reloadVideo()
          }
        })
      } else {
        this.streamer = new JSMpeg.Player(this.server + this.videoId, {
          canvas: this.$refs.video,
          disableGl: true,
          disableWebAssembly: true,
          onPlay: () => { this.loading = false },
          onStalled: () => { this.loading = true }
        })
      }
    },
    playback(start, end, source) {
      start = start + 600
      this.recordMinValue = start
      this.recordMaxValue = end
      this.recordValue = start
      this.recordSource = source
      this.subTitle = `(${this.dateFormat('yyyy-MM-dd HH:mm', new Date(start * 1000))}  至  ${this.dateFormat('yyyy-MM-dd HH:mm', new Date(end * 1000))})`
      this.showRecordControl = true
      this.playRecord(start, end, source)
    },
    playRecord(start, end, source) {
      this.loading = true
      this.recordOffset = start - this.recordMinValue
      this.disconnect()
      this.streamer = new JSMpeg.Player(this.server + this.videoId + `/${start}/${end}/${source}`, {
        canvas: this.$refs.video,
        disableGl: true,
        disableWebAssembly: true,
        onPlay: () => { this.loading = false },
        onStalled: () => { this.loading = true },
        onVideoDecode: decoder => {
          if (this.streamer && this.showRecordControl) {
            if (!this.recordDraging) {
              this.recordValue = this.recordMinValue + this.recordOffset + decoder.decodedTime
            }
          }
        }
      })
    },

    disconnect() {
      if (this.webrtc) {
        this.streamer.disconnect()
      } else if (this.rtsp) {
        this.streamer.pause()
        this.streamer.unload()
        this.streamer.detachMediaElement()
        this.streamer.destroy()
        this.streamer = null
      } else {
        try {
          this.streamer.source.destroy()
          this.streamer.destroy()
        } catch (e) {
          // console.log(e)
        }
        try {
          this.streamer.source.socket.close()
        } catch (e) {
          // console.log(e)
        }
      }
      if(this.websocket){
        try{
          this.websocket.close()
        } catch(e) {
          // console.log(e)
        }
      }
      this.loading = true
    },
    dateFormat(fmt, date) {
      let ret
      const opt = {
        'y+': date.getFullYear().toString(), // 年
        'M+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'm+': date.getMinutes().toString(), // 分
        's+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        }
      }
      return fmt
    }
  }
}
</script>
<style scoped>
.video-player{
  width:100%;
  height:100%;
  overflow: hidden;
  position: relative;
  color: #fff;
}
.loading{
  background-image: url(data:image/gif;base64,R0lGODlhJQAlAJECAL3L2AYrTv///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgACACwAAAAAJQAlAAACi5SPqcvtDyGYIFpF690i8xUw3qJBwUlSadmcLqYmGQu6KDIeM13beGzYWWy3DlB4IYaMk+Dso2RWkFCfLPcRvFbZxFLUDTt21BW56TyjRep1e20+i+eYMR145W2eefj+6VFmgTQi+ECVY8iGxcg35phGo/iDFwlTyXWphwlm1imGRdcnuqhHeop6UAAAIfkEBQoAAgAsEAACAAQACwAAAgWMj6nLXAAh+QQFCgACACwVAAUACgALAAACFZQvgRi92dyJcVJlLobUdi8x4bIhBQAh+QQFCgACACwXABEADAADAAACBYyPqcsFACH5BAUKAAIALBUAFQAKAAsAAAITlGKZwWoMHYxqtmplxlNT7ixGAQAh+QQFCgACACwQABgABAALAAACBYyPqctcACH5BAUKAAIALAUAFQAKAAsAAAIVlC+BGL3Z3IlxUmUuhtR2LzHhsiEFACH5BAUKAAIALAEAEQAMAAMAAAIFjI+pywUAIfkEBQoAAgAsBQAFAAoACwAAAhOUYJnAagwdjGq2amXGU1PuLEYBACH5BAUKAAIALBAAAgAEAAsAAAIFhI+py1wAIfkEBQoAAgAsFQAFAAoACwAAAhWUL4AIvdnciXFSZS6G1HYvMeGyIQUAIfkEBQoAAgAsFwARAAwAAwAAAgWEj6nLBQAh+QQFCgACACwVABUACgALAAACE5RgmcBqDB2MarZqZcZTU+4sRgEAIfkEBQoAAgAsEAAYAAQACwAAAgWEj6nLXAAh+QQFCgACACwFABUACgALAAACFZQvgAi92dyJcVJlLobUdi8x4bIhBQAh+QQFCgACACwBABEADAADAAACBYSPqcsFADs=);
  background-position: center center;
  background-repeat:  no-repeat;
}
video,canvas{
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
}
.video-title{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0.6);
  color:#fff;
  font-size: 0.8em;
  z-index: 2;
  padding: 0 10px;
}
.video-record-container{
  position: absolute;
  top: 24px;
  right:0px;
  width: 180px;
  z-index: 3;
}
.icon-close{
  font-size: 12px;
}
.video-toolbox{
  position: absolute;
  top:0;
  right : 10px;
  z-index: 3;
  list-style: none;
}
.video-toolbox li {
  float: left;
  margin: 0 3px;
  cursor: pointer;
  padding: 0 2px;
}
.video-toolbox li:hover,.video-record-container{
  background-color: #02518C;
}
.video-record-container{
  border: 1px solid rgb(17, 35, 76);
  box-shadow: inset 0 0 8px white;
  border-radius: 3px;
  background-image: linear-gradient(to left, #92aeff, #92aeff) left top no-repeat, linear-gradient(to bottom, #92aeff, #92aeff) left top no-repeat, linear-gradient(to left, #92aeff, #92aeff) right top no-repeat, linear-gradient(to bottom, #92aeff, #92aeff) right top no-repeat, linear-gradient(to left, #92aeff, #92aeff) left bottom no-repeat, linear-gradient(to bottom, #92aeff, #92aeff) left bottom no-repeat, linear-gradient(to left, #92aeff, #92aeff) right bottom no-repeat, linear-gradient(to left, #92aeff, #92aeff) right bottom no-repeat;
  background-size: 1px 12px, 12px 1px, 1px 12px, 12px 1px;
  padding: 8px;
  width: 240px;
}
.video-record-container .record-list{
  list-style: none;
  font-size: 12px;
  padding-top: 2px;
  border-top: 1px solid #11234c;
  margin-top: 3px;
}
.video-record-container .record-list li {
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  border-radius: 3px;
}
.video-record-container .record-list li:hover{
  background-color: rgba(255,255,255,0.18);
  color:#96d8ff;
}
.video-record-container button{
  display: inline-block;
  color: #fff;
  background: #3C7FB4;
  outline: 0;
  border: none;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 4px;
}
.video-record-container button:hover{
  background: #0E5AAF;
}
.video-record-container button:active{
  background: #49c3ff
}
.video-record-container button i{
  font-size: 12px;
  padding-right: 2px;
}

.vdp-datepicker >>> .vdp-datepicker__calendar{
  background: #02518C !important;
  border: 1px solid rgb(17, 35, 76);
  box-shadow: inset 0 0 8px white;
  border-radius: 3px;
  background: linear-gradient(to left, #92aeff, #92aeff) left top no-repeat, linear-gradient(to bottom, #92aeff, #92aeff) left top no-repeat, linear-gradient(to left, #92aeff, #92aeff) right top no-repeat, linear-gradient(to bottom, #92aeff, #92aeff) right top no-repeat, linear-gradient(to left, #92aeff, #92aeff) left bottom no-repeat, linear-gradient(to bottom, #92aeff, #92aeff) left bottom no-repeat, linear-gradient(to left, #92aeff, #92aeff) right bottom no-repeat, linear-gradient(to left, #92aeff, #92aeff) right bottom no-repeat;
  background-size: 1px 12px, 12px 1px, 1px 12px, 12px 1px;
  right: 0;
  width: 220px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar header .prev:not(.disabled):hover,
.vdp-datepicker >>> .vdp-datepicker__calendar header .next:not(.disabled):hover,
.vdp-datepicker >>> .vdp-datepicker__calendar header .up:not(.disabled):hover{
  background: rgba(0, 160, 255, 0.44);
}
.vdp-datepicker >>> .vdp-datepicker__calendar header span.prev::after{
  border-right-color: #fff !important;
}
.vdp-datepicker >>> .vdp-datepicker__calendar header span.next::after{
  border-left-color: #fff !important;
}
.vdp-datepicker{
  display: inline-block;
  width: 65px;
  margin:0 5px;
}
.vdp-datepicker >>> * {
  font-size: 12px;
}
.video-record-container span{
  font-size: 12px;
  margin-right: 5px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar header{
  line-height: 26px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar .cell{
  height: 22px;
  line-height: 22px;
}
.vdp-datepicker >>> input{
  outline: 0;
  background: none;
  border:none;
  color: #fff;
  width: 100%;
}

.video-record-controls{
  position: absolute;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  height: 24px;
  line-height: 24px;
  width: 100%;
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
  display: flex;
  z-index: 1;
}
.video-record-controls input{
  flex: 1;
  -webkit-appearance:none;
  height: 6px;
  line-height: 6px;
  margin-top:9px;
  outline:0;
  background: #555;
  border-radius: 4px;
}
.video-record-controls input::-webkit-slider-thumb{
  -webkit-appearance: none;
          appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
}
.video-record-controls span{
  width: 130px;
  white-space: nowrap;
  text-align: center;
}
</style>
