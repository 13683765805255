<template>
  <div class="video-wall-container">
    <div class="toolbox-container">
      <div class="box toolbox">
        <h6>工具栏</h6>
        <ul>
          <li>
            <i class="video-iconfont icon-clear" />
            <span>清除视频</span>
          </li>
          <li>
            <a style="color:white;text-decoration: none;" href="DSSClient://"><i class="video-iconfont icon-full" /></a>
            <span>打开软件</span>
          </li>
          <li @click.stop="()=>showLayoutCluster = true">
            <i class="video-iconfont icon-layout" />
            <span>视频布局</span>
          </li>
        </ul>
      </div>
      <div class="box controls">
        <div v-if="streams && selectIndex !== -1 && streams[selectIndex] && !streams[selectIndex].isControl" class="not-control">
          此摄像头不能控制
        </div>
        <template v-else>
          <div class="controls-zoom">
            <div><i class="video-iconfont icon-zoom-in" @click="sendCommand(11)" /></div>
            <div><i class="video-iconfont icon-zoom-out" @click="sendCommand(12)" /></div>
          </div>
          <ul>
            <li v-for="(item,i) in commands" :key="i">
              <div :class="{ active: controlClickIndex === i}" @click="sendCommand(item)" @mousedown="controlClickIndex = i" @mouseup="controlClickIndex = -1">
                <i class="video-iconfont icon-triangle-top" />
              </div>
            </li>
          </ul>
        </template>
      </div>
      <div class="box videos">
        <ul ref="tree" class="ztree" />
      </div>
    </div>
    <grid ref="grid" :layout="layout" :select-index.sync="selectIndex">
      <template #item="{ item }">
        <template v-if="streams[item.index]">
          <video-player
            :server="streams[item.index].server"
            :video-id="streams[item.index].id"
            :video-url="streams[item.index].videoUrl"
            :display-title="streams[item.index].title"
            :abilities="streams[item.index].abilities"
            @close="$set(streams,item.index,null);$refs.grid.full(-1)"
            @full="$refs.grid.full(item.index);"
          />
        </template>
      </template>
    </grid>
    <div v-if="showLayoutCluster" class="layout-cluster">
      <div v-for="(item,index) in layouts" :key="index" @click="chooseLayout(index)">
        <grid :layout="item[1]" style="width: 80px;height:80px" :allow-click="false" />
        <label>{{ item[0] }}</label>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/video/video-wall.css'
import '@/assets/video/plugins/ztree/jquery.ztree.all-3.5.min.js'
import '@/assets/video/plugins/ztree/ztree.css'
import '@/assets/video/iconfont/video-iconfont.css'
import '@/assets/video/index.css'
import Grid from './grid.vue'
import VideoPlayer from './video-player.vue'
const $ = require('jquery')
export default {
  name: 'VideoWall',
  components: {
    Grid, VideoPlayer
  },
  data() {
    return {
      showLayoutCluster: false,
      layout: undefined,
      streams: [],
      commands: [21, 26, 24, 28, 22, 27, 23, 25],
      selectIndex: -1,
      controlClickIndex: -1,
      layouts: [
        ['1x1', [1, 1, [1, 1, 1, 1]]],
        ['2x2', [2, 2, [1, 1, 1, 1], [1, 2, 1, 1], [2, 1, 1, 1], [2, 2, 1, 1]]],
        ['3x3', [3, 3, [1, 1, 1, 1], [1, 2, 1, 1], [1, 3, 1, 1], [2, 1, 1, 1], [2, 2, 1, 1], [2, 3, 1, 1], [3, 1, 1, 1], [3, 2, 1, 1], [3, 3, 1, 1]]],
        ['4x4', [4, 4, [1, 1, 1, 1], [1, 2, 1, 1], [1, 3, 1, 1], [1, 4, 1, 1], [2, 1, 1, 1], [2, 2, 1, 1], [2, 3, 1, 1], [2, 4, 1, 1], [3, 1, 1, 1], [3, 2, 1, 1], [3, 3, 1, 1], [3, 4, 1, 1], [4, 1, 1, 1], [4, 2, 1, 1], [4, 3, 1, 1], [4, 4, 1, 1]]],
        ['1+5', [3, 3, [1, 1, 2, 2], [1, 3, 1, 1], [2, 3, 1, 1], [3, 1, 1, 1], [3, 2, 1, 1], [3, 3, 1, 1]]],
        ['1+7', [4, 4, [1, 1, 3, 3], [1, 4, 1, 1], [2, 4, 1, 1], [3, 4, 1, 1], [4, 1, 1, 1], [4, 2, 1, 1], [4, 3, 1, 1], [4, 4, 1, 1]]],
        ['1+12', [4, 4, [1, 1, 1, 1], [1, 2, 1, 1], [1, 3, 1, 1], [1, 4, 1, 1], [2, 1, 1, 1], [2, 2, 2, 2], [2, 4, 1, 1], [3, 1, 1, 1], [3, 4, 1, 1], [4, 1, 1, 1], [4, 2, 1, 1], [4, 3, 1, 1], [4, 4, 1, 1]]],
        ['2+8', [4, 4, [1, 1, 2, 2], [1, 3, 2, 2], [3, 1, 1, 1], [3, 2, 1, 1], [3, 3, 1, 1], [3, 4, 1, 1], [4, 1, 1, 1], [4, 2, 1, 1], [4, 3, 1, 1], [4, 4, 1, 1]]]
      ],
      $tree: null
    }
  },
  async mounted() {
    // Extract token from URL if present
    const token = this.getTokenFromUrl()
    if (token) {
      // Set token in cookies
      this.setTokenInCookies(token)
    }
    
    this.layout = this.layouts[4][1] // 1+5
    // const videos = require('./videos.json')
    const data = await this.$get('camera/list', {})
    var videos = data.data.map(it => {
        return {
          'device_type': 3,
          'name': it.name,
          'videoId': it.cameraId,
          'videoUrl': it.videoUrl,
          'id': it.cameraId,
          'status': (it.deviceStatus == null) ? 1 : it.deviceStatus,
          'isControl': it.isControl === 1,
          'abilities': it.abilities
        }
      })
    // try {
    //   const response = await fetch(process.env.VUE_APP_VIDEO_SERVER + `status?deviceIds=${videos.map(it => it.id).join(',')}`)
    //   await response.json().then(it => {
    //     it && it.data && it.data.forEach(item => {
    //       videos.filter(v => v.id === item.id).forEach(v => {
    //         v.status = item.device_status
    //       })
    //     })
    //   })
    // } catch (e) {
    //   console.log(e)
    // }
    videos.forEach(it => {
      it.pId = '0'
      it.icon = it.status === 1 ? require('@/assets/video/video-online.png') : require('@/assets/video/video-offline.png')
    })
    videos.unshift({
      id: '0',
      name: '根节点',
      pId: 'root'
    })
    this.tree = $.fn.zTree.init($(this.$refs.tree), {
      view: {
        fontCss: (_treeId, node) => node.id !== '0' && node.status !== 1 ? { color: '#5E5F62' } : {}
      },
      data: {
        simpleData: {
          enable: true
        }
      },
      callback: {
        onDblClick: (_eventId, _treeId, node) => {
          if (node && !node.isParent) {	// 点击的是摄像头
            this.onVideoTreeClick(node)
          }
        }
      }
    }, videos)
    this.tree.expandAll(true)
    document.body.addEventListener('click', () => {
      this.showLayoutCluster = false
    })
  },
  methods: {
    onVideoTreeClick(video) {
      for (let i = 0; i < this.streams.length; i++) {
        if (this.streams[i] && this.streams[i].id === video.videoId) {
          this.selectIndex = i
          return
        }
      }
      const item = {
        id: video.videoId,
        title: video.name,
        server: video.device_type === 3 ? process.env.VUE_APP_VIDEO_WEBSOCKET_SERVER : process.env.VUE_APP_VIDEO_SERVER,
        videoUrl: video.videoUrl,
        abilities: video.abilities,
        isControl: video.isControl !== undefined ? video.isControl : true,
        full: false
      }
      let index = -1
      if (this.selectIndex !== -1) {
        index = this.selectIndex
        this.selectIndex = -1
      } else {
        for (let i = 0; i < this.layout.length; i++) {
          if (!this.streams[i]) {
            index = i
            break
          }
        }
        if (index === -1) {
          index = 0
        }
      }
      this.$set(this.streams, index, null)
      this.$nextTick(() => {
        this.$set(this.streams, index, item)
      })
    },
    sendCommand(cmd) {
      if (this.selectIndex !== -1) {
        try {
          const deviceId = this.streams[this.selectIndex].id
          fetch(process.env.VUE_APP_VIDEO_SERVER + 'video/control?deviceId=' + deviceId + '&operator=' + cmd)
        } catch (e) {
          console.log(e)
        }
      }
    },
    chooseLayout(index) {
      this.layout = this.layouts[index][1]
    },
    // Token handling
    getTokenFromUrl() {
      // Parse token from URL query parameters
      let queryString = window.location.hash.split('?')[1]
      if (!queryString && window.location.search) {
        // Also check regular query parameters
        queryString = window.location.search.substring(1)
      }
      
      if (queryString) {
        const params = new URLSearchParams(queryString)
        return params.get('token')
      }
      return null
    },
    
    setTokenInCookies(token) {
      // Import dynamically to avoid circular dependency
      import('@/utils/auth').then(auth => {
        auth.setToken(token)
      })
    },
  }
}
</script>
<style scoped>
.video-wall-container{
    position:relative;
    color: #fff;
}
.layout-cluster{
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: none;
    position: absolute;
    left: 5px;
    top: 90px;
    z-index: 1;
    background: #1f3c7a;
    color:#2499d7;
    border:1px solid rgba(170, 170, 170, 0.31);
    border-radius: 5px;
    grid-gap: 4px 4px;
    display: grid;
    padding:4px;
}
.layout-cluster > div{
    position: relative;
}
.layout-cluster > div > div{
    border-width: 2px;
    border-style: dashed;
    border-color: transparent;
}
.layout-cluster > div > div:hover{
    border-color: orange;
}
.layout-cluster label{
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 12px;
    background: #001339;
    height: 20px;
    line-height: 20px;
}
.toolbox-container{
    width : 245px;
}

.toolbox-container{
    display: flex;
    flex-direction: column;
}
.toolbox-container .toolbox{
    height : 120px;
}
.toolbox-container .toolbox ul{
    display: flex;
}
.toolbox-container .toolbox ul li {
    flex : 1;
    list-style: none;
    color : #fff;
    text-align: center;
}
.toolbox-container .toolbox ul li i{
    display: inline-block;
    margin: 0 auto;
    margin-top:5px;
    padding: 10px;
    font-size:18px;
    cursor: pointer;
}
.toolbox-container .toolbox ul li i:hover{
    background:rgba(0, 160, 255, 0.44);
    color:#dae0e5;
}
.toolbox-container .toolbox ul li span{
    font-size: 12px;
    display: block;
}

.toolbox-container .videos{
    flex : 1
}
.toolbox-container .box{
    border: 1px solid rgb(17, 35, 76);
    box-shadow: inset 0 0 8px white;
    border-radius: 3px;
    background: linear-gradient(to left, #92aeff, #92aeff) left top no-repeat,
        linear-gradient(to bottom, #92aeff, #92aeff) left top no-repeat,
        linear-gradient(to left, #92aeff, #92aeff) right top no-repeat,
        linear-gradient(to bottom, #92aeff, #92aeff) right top no-repeat,
        linear-gradient(to left, #92aeff, #92aeff) left bottom no-repeat,
        linear-gradient(to bottom, #92aeff, #92aeff) left bottom no-repeat,
        linear-gradient(to left, #92aeff, #92aeff) right bottom no-repeat,
        linear-gradient(to left, #92aeff, #92aeff) right bottom no-repeat;
    background-size: 1px 12px, 12px 1px, 1px 12px, 12px 1px;
 }
 .toolbox-container .controls{
     height: 200px;
     position: relative;
 }
 h6{
     color: #fff;
     text-align: center;
     font-size: 16px;
     height: 32px;
     line-height: 32px;
     font-weight: normal;
     border-bottom: 1px solid #223f81;
 }
 .controls ul{
    position: relative;
    width: 130px;
    height: 130px;
    transform: scale(1) rotate(90deg);
    border-radius: 50%;
    text-align: center;
    margin: auto;
    clear:both;
    overflow: hidden;
 }
 .controls li{
    list-style: none;
    border: 1px solid transparent;
    transform: rotate(-22.5deg) skew(45deg);
    transform-origin: 100% 100%;
    position:absolute;
    width: 50%;
    height: 50%;
    overflow: hidden;
 }
 .controls .controls-zoom{
     margin: 10px 0;
 }
.controls .controls-zoom div{
    display: inline-block;
    height : 24px;
    line-height: 24px;
    width: 24px;
    border-radius: 12px;
    text-align: center;
}
.controls .controls-zoom  div:first-child{
    float:left;
    margin-left: 20px;
}
.controls .controls-zoom div:last-child{
    float:right;
    margin-right: 20px;
}
.controls .controls-zoom div:hover{
    background: rgba(0, 160, 255, 0.43);

}
.controls .controls-zoom > div i{
    font-size: 22px;
}
.controls li div{
    background: radial-gradient(transparent 0%, transparent 35%, #3c7fb4 37%, #3c7fb4 67%, transparent 70%);
    position: absolute;
    width: 200%;
    height: 200%;
    line-height: 200%;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    transform: skew(-45deg) rotate(-67.5deg);
    cursor: default;
 }

.controls li div:hover{
    background:radial-gradient(transparent 0%, transparent 35%, rgba(14, 101, 197, 0.8) 37%, rgba(14, 101, 197, 0.8) 67%, transparent 70%)
}
.controls li div.active{
    background:radial-gradient(transparent 0%, transparent 35%, #49c3ff 37%, #49c3ff 67%, transparent 70%);
}
.controls li:nth-child(1){
    transform: rotate(-22.5deg) skew(45deg);
 }
.controls li:nth-child(2){
    transform: rotate(22.5deg) skew(45deg);
 }
.controls li:nth-child(3){
    transform: rotate(67.5deg) skew(45deg);
 }
.controls li:nth-child(4){
    transform: rotate(112.5deg) skew(45deg);
}
.controls li:nth-child(5){
    transform: rotate(157.5deg) skew(45deg);
}
.controls li:nth-child(6){
    transform: rotate(-157.5deg) skew(45deg);
}
.controls li:nth-child(7){
    transform: rotate(-112.5deg) skew(45deg);
}
.controls li:nth-child(8){
    transform: rotate(-67.5deg) skew(45deg);
}
.controls .not-control{
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  left: 5px;
  top: 5px;
  position: absolute;
  padding-top: calc((100% - 10px) / 3);
  text-align: center;
  z-index: 1;
  /* background-color: rgba(0,0,0,.4); */
  border-radius: 5px;
}

</style>
